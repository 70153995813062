import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, LineElement, PointElement, Tooltip, Legend, RadialLinearScale, Filler, ArcElement } from 'chart.js'
import { PolarArea } from 'react-chartjs-2'

ChartJS.register(
  LineElement, 
  PointElement, 
  Tooltip, 
  Legend, 
  RadialLinearScale,
  Filler,
  ArcElement
)

const MakeItEasy = () => {
    //const Navigate = useNavigate();

    const GraphPoints = JSON.parse(window.sessionStorage.getItem('welcomeSurveyResults'));

    const GraphBG = GraphPoints.map((a) => a < 4 ? "rgba(255, 0, 0, .3)" : a < 8 ? "rgba(255, 205, 41, .3)" : "rgba(139, 197, 63, .3)")

    const GraphData= {
        labels: ['Bienestar','Movimiento','Nutrición','Recuperación','Consumo'],
        datasets: [{
            label: " Puntaje",
            circular: false,
            data: GraphPoints,
            fill: true,
            borderColor: '#CCCCFF',
            pointBackgroundColor: '#6633FF',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)',
            backgroundColor: GraphBG,
            borderWidth: 1
        }],
    }
  
    const GraphOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
            display: false,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                ticks: {
                    display: false
                },
                pointLabels: {
                display: true,
                centerPointLabels: true,
                font: {
                    size: 14
                },
                padding: -20
                },
                grid: {
                circular: false
                }
            },
        },
        scale: {
            r: {
            min: 0, // MIN
            max: 10, // MAX
            beginAtZero: true,
            display: false,
            angleLines: {
                display: true,
                color: 'red'
            }
    
            },
            ticks: {
                beginAtZero: true,
                max: 10,
                min: 0,
                stepSize: 1
            },
            pointLabels: {
            display: true,
            font: {size: 20}
            },
            angleLines: {
            color: 'red'
            }
        },
    }


    return (
      <div className='UserWelcome'>
        <div className='UserWelcomeBlockPage2'>
            <div className='UserWelcomeResultsUpBanner'>
                <div className='UserWelcomeResultsInnerUpBanner'>
                    <img alt="" className='UserWelcomeBlockUpBannerTextsYMCIcon' src="https://static.yomecontrolo.cl/public/icons/YMC_Logo_NoText.png" id="RegisterYMCIcon"/>
                    <div className='UserWelcomeBlockUpBannerTexts'>
                        <div className='UserWelcomeResultsUpBannerSubtitle'>El cuestionario médico del estilo de vida se ha diseñado como una herramienta de detección que evalúa los hábitos diarios que pueden afectar a la salud.</div>
                    </div>
                </div>
                <h5 className='UserWelcomeResultsUpBannerUpTitle'>Puntaje de tus hábitos de vida</h5>
                <div className='UserWelcomeResultsUpBannerTitle'>{GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2}% {GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2 > 75 ? "Tienes buenos hábitos!" : GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2 > 35 ? "Hay espacio para mejorar" : "Te vendría bien un cambio de hábitos"}</div>
                <div className="UserWelcomeResultsUpBannerMeter" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontal.png')",backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat'}}>
                    {GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2 > 75 ?
                    <img alt="" className="UserWelcomeResultsUpBannerMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_LowRiskFace.png" style={{right:""+(GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2)-10+"%"}} />
                    : GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2 > 35 ?
                    <img alt="" className="UserWelcomeResultsUpBannerMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{right:""+(GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2)-10+"%"}} />
                    :
                    <img alt="" className="UserWelcomeResultsUpBannerMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_HighRiskFace.png" style={{right:""+(GraphPoints.reduce((partialSum, a) => partialSum + a, 0)*2)-10+"%"}} />
}
                </div>
            </div>
            <div className='UserWelcomeBlockResultsTitle'>Resultados Encuesta</div>
            <div className='UserWelcomeBlockResultsSubtitle'>Revisa tus resultados aquí y descarga tu orden médica personalizada gratis.</div>
            <div className='UserWelcomeBlockRadarGraph'>
                <PolarArea data={GraphData} options={GraphOptions}></PolarArea>
            </div>
            <div className='UserWelcomeResultsHealthFactorsBlocks'>
                <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex UserWelcomeResultsHealthFactorsPaddingBlock'>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                        <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage1' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskAlcohol.png'/>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                        <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+5</h3>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Menos alcohol por más años de vida.</div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle'>Si disminuyes el consumo de bebidas alcohólicas puedes alcanzar hasta 5 años más de vida</div>
                    </div>
                </div>
                <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex UserWelcomeResultsHealthFactorsPaddingBlock'>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                        <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage2' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskSmoking.png'/>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                        <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+4</h3>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Fumar nunca sumará a tu salud</div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle'>Si dejas de fumar no solo mejoras tu salud diaria, también puedes ganar hasta 10 años de vida.</div>
                    </div>
                </div>
                <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex UserWelcomeResultsHealthFactorsPaddingBlock'>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                        <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage3' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskIMC.png'/>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                        <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+4</h3>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Cuida tu Índice de Masa Corporal (IMC)</div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle'>Si cuidas tu índice de masa corporal puedes ganar 4 años aproximados de vida</div>
                    </div>
                </div>
                <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex UserWelcomeResultsHealthFactorsPaddingBlock'>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                        <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage4' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskCholesterol.png'/>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                        <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+7.2</h3>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Para cuidar el colesterol</div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle Italic'>Limita los alimentos grasos y salados, come sin culpa de frutas de verduras y legumbres. Evita dulces, precocinados y alimentos ultra procesados.
                        Mantenerte en un peso saludable te puede sumar hasta 7.2 años de vida.</div>
                    </div>
                </div>
                <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex'>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                        <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage5' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskMovement.png'/>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                        <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+3.4</h3>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>El movimiento es vida</div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle Italic'>Te recomendamos agregar dentro de tu semana 150-300 min de actividades de intensidad moderada ó 75 a 150 min de ejercicios de intensidad alta. Lo importantes es que mantengas tu cuerpo en movimiento adaptado a tu condición física.
                        Puedes ganar hasta 3.4 años de vida</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
};

export default MakeItEasy;